import {
  trackUserEventService,
  updateAnonymousUserService,
} from "./userEvent";
import { ZERO_MATCHES_EVENT_TYPE } from "../constants/userEvents";
import { ENDPOINT_PROFILE } from "../endpoints/url";
import requestHeader from "../Header";

export const generateProfileService = async (quizSelections, userSource, userSourceId) => {
  console.log("INSIDE GENERATE PROFILE");

  try {
    const response = await fetch(`${ENDPOINT_PROFILE}/generateprofile`, {
      method: "POST",
      headers: requestHeader,
      credentials: "include",
      body: JSON.stringify({ selected: quizSelections, userSource: userSource, userSourceId:userSourceId }),
    });

    if (response.ok) {
      const res = await response.json();
      const matchmaker = res.matchmaker;

      if (matchmaker.profileQuizMaeTrims.length === 0) {
        trackUserEventService(ZERO_MATCHES_EVENT_TYPE, {
          profileQuiz: matchmaker.profileQuiz,
        });
      }
      const profileQuizMaeTrimIds = matchmaker?.profileQuizMaeTrims.map(
        (car) => {
          return car._maeTrimId;
        }
      );
      updateAnonymousUserService({
        profileQuiz: matchmaker.profileQuiz,
        profileQuizMaeTrimIds,
        numberOfMatches: matchmaker.profileQuizMaeTrims.length,
      });
      return res;
    }
    const errorData = await response.json();
    console.log("Failed:", errorData);
    return null;
  } catch (error) {
    console.log("Error:", error.message);
  }
};

export const generateAnonymousProfileService = async (quizSelections, userSource, userSourceId) => {
  console.log("INSIDE GENERATE PROFILE ANONYMOUS");

  try {
    const response = await fetch(`${ENDPOINT_PROFILE}/generateprofileanonymous`, {
      method: "POST",
      headers: requestHeader,
      body: JSON.stringify({ selected: quizSelections, userSource: userSource, userSourceId:userSourceId}),
    });

    if (response.ok) {
      let res = await response.json();
      let matchmaker = res.matchmaker;
      localStorage.setItem("anonymous-profile", JSON.stringify(matchmaker));

      // eslint-disable-next-line eqeqeq
      if (matchmaker.profileQuizMaeTrims.length == 0) {
        trackUserEventService(ZERO_MATCHES_EVENT_TYPE, {
          profileQuiz: matchmaker.profileQuiz,
        });
      }
      const profileQuizMaeTrimIds = matchmaker?.profileQuizMaeTrims.map(
        (car) => {
          return car._maeTrimId;
        }
      );
      updateAnonymousUserService({
        profileQuiz: matchmaker.profileQuiz,
        profileQuizMaeTrimIds: profileQuizMaeTrimIds,
        numberOfMatches: matchmaker.profileQuizMaeTrims.length,
      });
      return res;
    } else {
      const errorData = await response.json();
      console.log("Failed:", errorData.error);
      return null;
    }
  } catch (error) {
    console.log("Error:", error.message);
  }
};
